import React from "react";
import { graphql, Link } from "gatsby";
import { Breadcrumb } from "react-bootstrap";

import Layout from "../components/layout";
import Post from "../components/post";

import styles from "./post-template.module.scss";

const PostTemplate = ({ data }) => {
  const title = data.content.frontmatter.title;
  const description = data.content.frontmatter.description;
  const date = data.content.frontmatter.date;
  const body = data.content.html;
  const featuredImage = data.content.frontmatter.featured_image;
  const tags = data.content.frontmatter.tags;

  return (
    <Layout title={title} description={description}>
      <div className={styles.container}>
        <Breadcrumb>
          <Link to="/">Home</Link>
          <p className="pr-2 pl-2">/</p>
          <Link to="/articles">Articles</Link>
          <p className="pr-2 pl-2">/</p>
          <Breadcrumb.Item active>{title}</Breadcrumb.Item>
        </Breadcrumb>
        <Post
          title={title}
          description={description}
          featuredImage={featuredImage}
          date={date}
          body={body}
          tags={tags
            .filter(Boolean)
            .map(
              (tag) =>
                `${tag.charAt(0).toUpperCase()}${tag.toLowerCase().slice(1)}`,
            )}
        />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query PostBySlug($slug: String!) {
    content: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "DD MMMM YYYY")
        description
        title
        tags
        featured_image {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`;

export default PostTemplate;
