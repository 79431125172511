import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import { Badge } from "react-bootstrap";

import styles from "./post.module.scss";

function createMarkup(data) {
  return { __html: data };
}

const Post = ({ title, description, date, body, featuredImage, tags }) => {
  return (
    <div className={`${styles.article} mt-5`}>
      <div className={`article__header`}>
        <h1>{title}</h1>
        <h2>{description}</h2>
      </div>
      <div className={`article__header-image`}>
        <Img
          loading="eager"
          fluid={featuredImage.childImageSharp.fluid}
          placeholderStyle={{ visibility: "hidden" }}
        />
      </div>
      <div
        className={`article__content mt-3`}
        dangerouslySetInnerHTML={createMarkup(body)}
      />
      <div className={`article__footer mt-5`}>
        <div className={`article__footer__date text-muted`}>
          <p>Published on: {date}</p>
        </div>
        <div className={`article__footer__tags`}>
          {tags.map((tag, index) => (
            <Badge key={index} pill variant="light">
              {tag}
            </Badge>
          ))}
        </div>
      </div>
    </div>
  );
};

Post.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.string,
  body: PropTypes.string,
  featuredImage: PropTypes.object,
  tags: PropTypes.arrayOf(PropTypes.string),
};

Post.defaultProps = {
  title: "",
  description: "",
  date: "",
  body: "",
  featuredImage: "",
  tags: [],
};

export default Post;
